import { CustomCellRendererProps } from "ag-grid-react";
import { DocumentDetailItemEntity } from "domain/entity/Document/DocumentDetailItemEntity";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { DocumentEnquiryConstant } from "./DocumentEnquiryConstant";

const DOC_ENQ_DTL_CONST = DocumentEnquiryConstant.Detail;
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
let timeFieldList: string[] = [];

export const INITIAL_DOCUMENT_ENQUIRY_DETAIL_COL_DEF: any[] = [
    {
        headerName: DOC_ENQ_DTL_CONST.DTL_NO,
        field: 'detailNo',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: DOC_ENQ_DTL_CONST.TAR_DESC,
        field: 'tariffCodeDesc',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 250,
    },
    {
        headerName: DOC_ENQ_DTL_CONST.TAR_TYPE,
        field: 'tariffType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_DTL_CONST.TAR_CODE,
        field: 'tariffCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_DTL_CONST.CHG_TYPE,
        field: 'chargeType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: DOC_ENQ_DTL_CONST.RATE_INV_CURR,
        field: 'rateInvoiceCur',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_DTL_CONST.RATE_CONT_CURR,
        field: 'rateContractCur',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_DTL_CONST.RATE_LOC_CURR,
        field: 'rateLocalCur',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_DTL_CONST.AMT_INV_CURR,
        field: 'invoiceAmount',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_DTL_CONST.AMT_CONT_CURR,
        field: 'convertedAmount',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_DTL_CONST.AMT_LOC_CURR,
        field: 'localAmount',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: DOC_ENQ_DTL_CONST.FROM_DATE,
        field: 'fromDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
        dataType: "dateTime",
    },
    {
        headerName: DOC_ENQ_DTL_CONST.TO_DATE,
        field: 'toDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
        dataType: "dateTime",
    },
    {
        headerName: DOC_ENQ_DTL_CONST.CHG_QTY,
        field: 'chargingQty',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_DTL_CONST.CHG_QTY_UOM,
        field: 'chargingUOM',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_DTL_CONST.REV_COMPANY_CODE,
        field: 'revenueCompanyCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 250,
    },
    {
        headerName: DOC_ENQ_DTL_CONST.INTER_COMPANY_CODE,
        field: 'intercompanyCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 250,
    },
    {
        headerName: DOC_ENQ_DTL_CONST.REV_AC_CODE,
        field: 'revenueAccountCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_DTL_CONST.COST_CENTER_CODE,
        field: 'costCenterCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_DTL_CONST.PROD_SER_CODE,
        field: 'productServiceCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 250,
    },
    {
        headerName: DOC_ENQ_DTL_CONST.SALES_CHANNEL_CODE,
        field: 'salesChannelCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
    },
    {
        headerName: DOC_ENQ_DTL_CONST.COUNTRY_CODE,
        field: 'countryCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
    },
    {
        headerName: DOC_ENQ_DTL_CONST.PROJECT_CODE,
        field: 'projectCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
    },
    {
        headerName: DOC_ENQ_DTL_CONST.SPARE_CODE,
        field: 'spareCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
    },
    {
        headerName: DOC_ENQ_DTL_CONST.TAX_PERCENTAGE,
        field: 'adjPercentage',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_DTL_CONST.TAX_AMT_INV_CURR,
        field: 'adjInvoiceAmount',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 250,
    },
    {
        headerName: DOC_ENQ_DTL_CONST.TAX_AMT_CONT_CURR,
        field: 'adjConvertedAmount',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_DTL_CONST.TAX_AMT_LOC_CURR,
        field: 'adjLocalAmount',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 250,
    },
    {
        headerName: DOC_ENQ_DTL_CONST.ARTICLE_STATEMENT,
        field: 'articleStatement',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 300,
    },
    {
        headerName: DOC_ENQ_DTL_CONST.TAX_CODE,
        field: 'taxCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_DTL_CONST.TAX_PAYMENT_REF_TERM,
        field: 'taxPaymentRefDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 250,
        dataType: 'date'
    },
    {
        headerName: DOC_ENQ_DTL_CONST.TX_TYPE,
        field: 'transactionType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_DTL_CONST.TAX_PAYMENT_TERM,
        field: 'adjDay',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_DTL_CONST.TAX_DATE,
        field: 'adjDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
        dataType: 'date'
    },
]?.map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, timeFieldList, cellRenderers);
});

export const transferRowData = (data: DocumentDetailItemEntity[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};

    return transferRowDataInternal<DocumentDetailItemEntity>(data, dateFieldList, dateTimeFieldList, timeFieldList, externalFnctions);
}